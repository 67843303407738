import FileMetaDTO from "@/dto/files/FileMetaDTO";

export default class IncomeTaxReturnDocumentDTO {

    public id!: number;
    public personId!: number;
    public formId!: string;
    public category!: string;
    public year!: string;
    public file!: FileMetaDTO;
    public createDate!: Date;


    get getFormattedDate() {
        if (!this.createDate) {
            return '';
        }
        const date = new Date(this.createDate);
        return date.toLocaleDateString();
    }

}

export const FEDERAL_CATEGORY = 'FEDERAL';
export const STATE_CATEGORY = 'STATE'