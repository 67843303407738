export default class IncomeTaxReturnRequest {
    public fileMetaId: number | null = null;

    public personId: number | null = null;

    public year: string | null = null;

    public formId: string | null = null;

    public category: IncomeTaxReturnType | null = null;

}

export type IncomeTaxReturnType = "FEDERAL" | "STATE"